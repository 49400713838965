export function formatDate(date: string, separator = "-") {
  if (date) {
    const dateObject = new Date(date);
    const day = dateObject.getDate();
    const month = dateObject.getMonth() + 1;
    const year = dateObject.getFullYear();
    return `${year}${separator}${appendLeadingZeroes(month)}${separator}${appendLeadingZeroes(day)}`;
  }
  return "";
}

export function formatTime(date: string) {
  if (date) {
    const dateObject = new Date(date);
    const hours: string | number = appendLeadingZeroes(dateObject.getHours());
    const minutes: string | number = appendLeadingZeroes(dateObject.getMinutes());
    return `${hours}:${minutes}`;
  }
  return "";
}

function appendLeadingZeroes(n: any) {
  if (n <= 9) {
    return "0" + n;
  }
  return n;
}